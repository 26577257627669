:root {
  --terminal-green: #4AF626;
}

*, *:after, *:before {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
* {
  margin:0;
  padding:0;
  border: 0 none;
  position: relative;
}

html, body {
  background: black;
  font-weight: 300;
  font-family: 'Noto Sans Mono', monospace;
}

.App {
  text-align: center;
  position: relative;
}

.app-row {
  display: block
}

h3 {
  padding: 15px 0;
  margin: 0;
}

.navigation-wrapper {
  color: white;
  position: fixed;
  z-index: 100;
}

.navigation-wrapper li {
  list-style: none;
  padding: 10px;
  text-align: left;
}

.navigation-wrapper a {
  text-decoration: none;
  color: inherit;
}

.hamburger-menu {
  display: none;
}

.hamburger-menu .line {
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: #cdcdcd;
}

.mobile-items {
  display: none;
}

.landing-page {
  background: black;
  height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-page button {
  display: block;
  margin: 50px auto;
  color: white;
  background-color: black;
  cursor: pointer;
}

#hero-image-desktop {
  width: 600px;
}

/* biography */
.biography-wrapper {
  background-image: url('./assets/bio_background.png');
  width: 100%;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  padding: 100px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.biography-content {
  background: rgba(0,0,0,0.5);
  padding: 40px;
  margin: 0 auto;
  color: white;
  max-width: 1000px;
}

.biography-content > p {
  text-align: left;
}

/* tour dates */

.tour-dates {
  background: black;
  color: var(--terminal-green);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tour-dates > div {
  width: 100vw;
}

.tour-dates-table {
  margin: auto;
  background: black;
}

.table-row {
  background: inherit;
  width: 70%;
  max-width: 40rem;
  min-width: 300px;
  margin: 0 auto;
  font-size: 1.2rem;
  line-height: 1.5rem;
  padding-right: 4rem;
  margin-bottom: .5rem;
  text-align: left;
}

.table-row:before {
  content: '';
  position: absolute;
  bottom: .4rem;
  width: 100%;
  height: 0;
  line-height: 0;
  border-bottom: 2px dotted var(--terminal-green);
}

.table-left {
  text-align: left;
  background: inherit;
  display: inline;
  z-index: 1;
  padding-right: .2rem;
}

.table-right {
  text-align: right;
  background: black;
  position: absolute;
  min-width: 4rem;
  bottom: 0;
  right: 0;
  padding-left: .2rem;
  text-align: right;
  z-index: 2;
}

.new-music {
  background-color: var(--terminal-green);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-music > div {
  width: 100vw;
}

@media only screen and (max-width: 600px) {
  .hamburger-menu {
    display: block;
    margin: 20px 0 0 20px;
  }

  .mobile-items {
    display: block;
    margin-left: 14px;
    background-color: black;
  }

  .desktop-items {
    display: none;
  }

  .biography-wrapper {
    padding: 0;
  }

  #hero-image-desktop {
    width: 80%;
  }

  .table-row {
    width: 90%;
  }
}
